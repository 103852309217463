import React from "react"
import Layout from "../../layouts/Layout"
import styled from "styled-components"

const StyleAboutContainer = styled.div`
  animation-duration: 2s;
  max-width: 1024px;
  margin: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 0 1.4rem;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    padding: 0;
  }
`
const StyledAboutTitle = styled.h1`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 42px;
  font-weight: 800;
  text-decoration: none;
  margin-top: 1rem;
`

const StyledAboutSubtitle = styled.p`
  color: ${({ theme }) => theme.colors.paragraph};
  font-size: 18px;
`

const StyledAboutContent = styled.div`
  color: ${({ theme }) => theme.colors.paragraph};
  h2 {
    margin-top: 24px;
    font-family: ${({ theme }) => theme.family.kowaski};
    font-size: 30px;
  }
`

const metadata = {
  title: "Acerca de mí · Nicolás Díaz",
  description:
    "Me encanta dar vida a las ideas que se pueden realizar a través de la tecnología, por eso mi interés se centra en diseñar y programar interfaces, siempre orientado a mantener un equilibrio entre belleza y funcionalidad.",

  cover: "/images/covers/about_cover.png",
  lang: "es",
  type: "website",
  path: "/about",
}

const AboutPage = () => (
  <Layout metadata={metadata}>
    <StyleAboutContainer>
      <StyledAboutTitle>Sobre Nicolás.</StyledAboutTitle>
      <StyledAboutContent>
        <StyledAboutSubtitle>
          Cuidar los diferentes aspectos en la construcción de productos
          tecnológicos, he acumulado múltiples experiencias como diseñador y
          desarrollador, que me han permitido ver el completo panorama desde
          cómo se ve una interfaz, hasta cómo resulta en el experiencia con los
          usuarios. El camino de mi trabajo pasa por escuchar el idea,
          determinando su viabilidad, generando un primer diseño, establecer un
          prototipo, desarrollarlo y programarlo. {""}
        </StyledAboutSubtitle>
        <StyledAboutContent>
          <h2>Lo que hago</h2>
          <p>
            Me encanta dar vida a las ideas que se pueden realizar a través de
            tecnología, por lo que mi interés se centra en diseñar y programar
            interfaces, siempre orientadas a mantener un equilibrio entre
            belleza y funcionalidad.
          </p>
          <h2>Mis habilidades</h2>
          <p>
            Actualmente mis habilidades se centran en la programación de
            JavaScript fullstack con herramientas como reactjs, nestjs y también
            la creación de paquetes npm. Con respecto a mis habilidades de
            diseño, tengo una lista de proyectos en mi portafolio enfocados en
            las interfaces de usuario de proyectos de la vida real donde
            demuestro mi conocimiento. Ahora mismo mis programas favoritos son
            Adobe XD para hacer aplicaciones y After Effect para animar algunas
            de mis creaciones.
          </p>
        </StyledAboutContent>
      </StyledAboutContent>
    </StyleAboutContainer>
  </Layout>
)

export default AboutPage
